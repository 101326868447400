// Generated by Framer (9f68555)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/syQmbCycd";

const serializationHash = "framer-XTLid"

const variantClassNames = {HQ9X4uGS8: "framer-v-kcbqiu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({content, height, icon, id, width, ...props}) => { return {...props, bLXdrbg_U: content ?? props.bLXdrbg_U ?? "24/7 Support", Pbq1lTQjQ: icon ?? props.Pbq1lTQjQ ?? {src: "https://framerusercontent.com/images/QrAZZEpxwyfDJXTIkPVFLNXkm10.svg"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;icon?: {src: string; srcSet?: string};content?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Pbq1lTQjQ, bLXdrbg_U, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "HQ9X4uGS8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-kcbqiu", className, classNames)} data-framer-name={"Plan Includes"} layoutDependency={layoutDependency} layoutId={"HQ9X4uGS8"} ref={ref ?? ref1} style={{...style}}><Image background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition(((componentViewport?.y || 0) + (0 + ((((componentViewport?.height || 25) - 0) - 25) / 2)))), pixelHeight: 25, pixelWidth: 25, sizes: "25px", ...toResponsiveImage(Pbq1lTQjQ), ...{ positionX: "center", positionY: "center" }}} className={"framer-184nv27"} data-framer-name={"Icon"} layoutDependency={layoutDependency} layoutId={"ud5VHKfbO"}/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1aqdup2"} data-styles-preset={"syQmbCycd"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-8d7e0ee3-cbeb-4f0c-8fae-b1180c2c0a4a, rgb(173, 176, 183)))"}}>24/7 Support</motion.p></React.Fragment>} className={"framer-uwslwc"} data-framer-name={"Text"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"VzW1sfa7b"} style={{"--extracted-r6o4lv": "var(--token-8d7e0ee3-cbeb-4f0c-8fae-b1180c2c0a4a, rgb(173, 176, 183))", "--framer-paragraph-spacing": "0px"}} text={bLXdrbg_U} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XTLid.framer-cvw3sc, .framer-XTLid .framer-cvw3sc { display: block; }", ".framer-XTLid.framer-kcbqiu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 6px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: min-content; }", ".framer-XTLid .framer-184nv27 { flex: none; height: 25px; overflow: hidden; position: relative; width: 25px; }", ".framer-XTLid .framer-uwslwc { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XTLid.framer-kcbqiu { gap: 0px; } .framer-XTLid.framer-kcbqiu > * { margin: 0px; margin-left: calc(6px / 2); margin-right: calc(6px / 2); } .framer-XTLid.framer-kcbqiu > :first-child { margin-left: 0px; } .framer-XTLid.framer-kcbqiu > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 124
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"Pbq1lTQjQ":"icon","bLXdrbg_U":"content"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Frameri5OFPGBzg: React.ComponentType<Props> = withCSS(Component, css, "framer-XTLid") as typeof Component;
export default Frameri5OFPGBzg;

Frameri5OFPGBzg.displayName = "Helper/Plan Includes";

Frameri5OFPGBzg.defaultProps = {height: 25, width: 124};

addPropertyControls(Frameri5OFPGBzg, {Pbq1lTQjQ: {__defaultAssetReference: "data:framer/asset-reference,QrAZZEpxwyfDJXTIkPVFLNXkm10.svg?originalFilename=Icon.svg&preferredSize=auto", title: "icon", type: ControlType.ResponsiveImage}, bLXdrbg_U: {defaultValue: "24/7 Support", displayTextArea: false, title: "Content", type: ControlType.String}} as any)

addFonts(Frameri5OFPGBzg, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})